interface CountryInfo {
  code: string;
  label: string;
  optionLabel: string;
  mask: string;
  placeholder: string;
  value: string,
}

export const COUNTRY_LIST: CountryInfo[] = [
  { value: 'RU', code: '7', label: '🇷🇺 +7', optionLabel: '🇷🇺 Россия <span>+7</span>', mask: '(###) ###-##-##', placeholder: '(999) 000-00-00' },
  { value: 'AZ', code: '994', label: '🇦🇿 +994', optionLabel: '🇦🇿 Азербайджан <span>+994</span>', mask: '##-###-##-##', placeholder: '00-000-00-00' },
  { value: 'BY', code: '375', label: '🇧🇾 +375', optionLabel: '🇧🇾 Беларусь <span>+375</span>', mask: '(##) ###-##-##', placeholder: '(99) 000-00-00' },
  { value: 'GE', code: '995', label: '🇬🇪 +995', optionLabel: '🇬🇪 Грузия <span>+995</span>', mask: '(###) ###-###', placeholder: '(999) 000-000' },
  { value: 'KZ', code: '7', label: '🇰🇿 +7', optionLabel: '🇰🇿 Казахстан <span>+7</span>', mask: '(###) ###-##-##', placeholder: '(999) 000-00-00' },
  { value: 'KG', code: '996', label: '🇰🇬 +996', optionLabel: '🇰🇬 Кыргызстан <span>+996</span>', mask: '(###) ###-###', placeholder: '(999) 000-000' },
  { value: 'MD', code: '373', label: '🇲🇩 +373', optionLabel: '🇲🇩 Молдавия <span>+373</span>', mask: '####-####', placeholder: '0000-0000' },
  { value: 'TJ', code: '992', label: '🇹🇯 +992', optionLabel: '🇹🇯 Таджикистан <span>+992</span>', mask: '##-###-####', placeholder: '00-000-0000' },
  { value: 'BR', code: '55', label: '🇧🇷 +55', optionLabel: '🇧🇷 Бразилия <span>+55</span>', mask: '(##) ####-####', placeholder: '(00) 0000-0000' },
  { value: 'CN', code: '86', label: '🇨🇳 +86', optionLabel: '🇨🇳 Китай <span>+86</span>', mask: '(###) ####-####', placeholder: '(000) 0000-0000' },
  { value: 'IN', code: '91', label: '🇮🇳 +91', optionLabel: '🇮🇳 Индия <span>+91</span>', mask: '(####) ###-###', placeholder: '(0000) 000-000' },
  { value: 'IR', code: '98', label: '🇮🇷 +98', optionLabel: '🇮🇷 Иран <span>+98</span>', mask: '(###) ###-####', placeholder: '(000) 000-0000' },
  { value: 'UA', code: '380', label: '🇺🇦 +380', optionLabel: '🇺🇦 Украина <span>+380</span>', mask: '(##) ###-##-##', placeholder: '(99) 000-00-00' },
]