<script setup lang="ts">
  import { COUNTRY_LIST } from './countryList'
  import UiTextField from '@/components/ui/TextField/index.vue';
  
  interface Props {
    modelValue: string
    title: string
    placeholder?: string
    error?: string | boolean
    maska?: string
  }

  interface ComputedMask {
    placeholder: string;
    mask: string;
    code: string;
  }

  const props = defineProps<Props>()
  const isOptionsOpened = ref(false)
  const inputRef = ref<InstanceType<typeof UiTextField> | null>(null);
  const originalPhone = ref('')

  const localCountryCode = computed({
    get: () => splitPhoneNumber(props.modelValue).countryCode,
    set: (newValue) => {
      const updatedPhone = splitPhoneNumber(props.modelValue).number 

      if (originalPhone.value.length < updatedPhone.length) {
        originalPhone.value = updatedPhone
      }

      const countryInfo = COUNTRY_LIST.find(country => country.value === newValue)
      if (countryInfo) {
        const updatedValue = countryInfo.code + originalPhone.value
        emit('update:modelValue', updatedValue)
      }
    }
  })

  const localPhoneNumber = computed(() => splitPhoneNumber(props.modelValue).number)

  const emit = defineEmits<{ 'update:modelValue': [value: string] }>()

  const handleOptionsOpened = (isOpen: boolean) => {
    isOptionsOpened.value = isOpen
  }

  const computedMaskInfo = computed<ComputedMask>(() => {
    let result = {
      placeholder: '(000)-000-00-00',
      mask: '(###)-###-##-##',
      code: '+7'
    }

    const countryInfo = COUNTRY_LIST.find(country => country.value === localCountryCode.value)

    if (countryInfo) {
      result.placeholder = countryInfo.placeholder
      result.mask = countryInfo.mask
      result.code = countryInfo.code
    }

    return result
  })

  const getMaxNumericLength = (mask: string): number => {
    return mask.split('').filter(char => char === '#').length
  }


  let updatedValue = ref('')

  const handleKeyUp = (event: KeyboardEvent) => {
    const inputValue = (event.target as HTMLInputElement).value
    const numericValue = inputValue?.replace(/\D/g, '')
    updatedValue.value = computedMaskInfo.value.code + numericValue
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    const inputValue = (event.target as HTMLInputElement).value
    const numericValue = inputValue.replace(/\D/g, '')
    const maxLength = getMaxNumericLength(computedMaskInfo.value.mask)
    const selectionStart = (event.target as HTMLInputElement).selectionStart
    const selectionEnd = (event.target as HTMLInputElement).selectionEnd
    const isSelection = selectionStart !== selectionEnd

    if (numericValue.length >= maxLength && event.key.match(/\d/) && !isSelection) {
      event.preventDefault()
      return
    }

    if (event.key === 'Enter') {
      handleBlur()
    }
  }

  const handleBlur = () => {
    if (updatedValue.value) {
      emit('update:modelValue', updatedValue.value)
    }
  }

  function splitPhoneNumber(phoneNumber: string): { countryCode: string, number: string } {
    if (!phoneNumber) {
      let number = ''
      let countryCode = "RU"
      return { countryCode, number }
    }

    let maxMatch = '';
    let countryCode = '';

    for (const country of COUNTRY_LIST) {
      if (phoneNumber.startsWith(country.code) && country.code.length > maxMatch.length) {
        maxMatch = country.code;
        countryCode = country.value;
      }
    }

    let number = phoneNumber.slice(maxMatch.length);

    return { countryCode, number };
  }

  const focus = () => {
    inputRef.value?.focus()
  }

  defineExpose({
    focus
  })
</script>

<template lang="pug">
ui-text-field.phone-input(
  v-model="localPhoneNumber"
  type="tel"
  :title="title"
  :placeholder="placeholder || computedMaskInfo.placeholder"
  :error="error"
  @keydown="handleKeyDown"
  @keyup="handleKeyUp"
  @blur="handleBlur"
  :maska="maska || computedMaskInfo.mask"
  ref="inputRef"
  isAdaptiveHeight
)
  template(#prepend)
    //- TODO: return arrow and isDisabled for all countries
    //- span.arrow(:class="{ 'arrow--is-opened': isOptionsOpened }")
    ui-select.select(
      v-model="localCountryCode"
      :options="COUNTRY_LIST"
      @options-opened="handleOptionsOpened"
      isEmbedded
      isAdaptiveHeight
      isDisabled
    )
</template>

<style lang="scss" scoped>
  /* .phone-input {
    @include mobile {
      &:deep(.AppTextFieldLabel--tel) {
        width: fit-content;
      }
    }
  } */

  .select {
    user-select: none;

    @include tablet {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-shrink: 0;
      min-width: get-vw(45, 'md');
      align-items: center;
      /* flex: 1; */
      &:deep(.InputContainer) {
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        height: 100%;
        .AppTextField {
          height: 100%;
          .AppTextFieldLabel {
            width: 100%;
            height: 100%;
            & > div {
              width: 100%;
              height: 100%;
              .AppTextFieldInnerInput {
                width: 100%;
                text-align: center;
                height: 100%;
              }
            }
          }
        }
      }

      & + :deep(.AppTextFieldInnerInput) {
        flex: 4;
      }
    }

    @include mobile {
      min-width: get-vw(45, 'sm');
      &:deep(.AppTextFieldLabel > div) {
        margin-right: get-vw(8, 'sm');
        .AppTextFieldInnerInput {
          width: 100% !important;
        }
      }
    }

    &:deep(.ui-select__options) {
      left: get-vw(-2);
      max-width: calc(100% + get-vw(4));
      width: calc(100% + get-vw(4));
    }

    // TODO: return cursor pointer for all countries
    &:deep(.AppTextFieldInnerInput),
    &:deep(.ui-select) {
      cursor: default;
    }
  }


  .arrow {
    background-image: url(@/assets/svg/arrow-down.svg);
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    background-size: get-vw(14);
    margin-right: get-vw(8);
    margin-left: get-vw(-8);
    transition: all 0.2s linear;
    width: get-vw(28);
    height: get-vw(28);

    &.arrow--is-opened {
      transform: rotate(180deg);
    }
  }

  @include tablet {
    .arrow {
      margin-left: get-vw(2, "md");
      width: get-vw(14, "md");
      height: get-vw(14, "md");
      background-size: contain;
    }
  }

  @include mobile {
    .arrow {
      margin-left: get-vw(3, "sm");
      margin-right: get-vw(3, "sm");
      width: get-vw(14, "sm");
      height: get-vw(14, "sm");
    }
  }
</style>
