<script setup lang="ts">
type Value = string | number
type Option = {
  value: Value
  label: string
  optionLabel?: string
}

interface Props {
  modelValue: Value
  options: Option[]
  title?: string
  placeholder?: string
  error?: string | boolean
  useVirtualScroll?: boolean
  isEmbedded?: boolean
  isAdaptiveHeight?: boolean
  isDisabled?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  'update:modelValue': [value: Value]
  'options-opened': [isOpen: boolean]
}>()

const optionsOpened = ref(false)
const isMobile = ref(false) //TODO: add mobile detection

const selectedOption = computed<Option | undefined>(() => {
  return props.options.find((option) => option.value === props.modelValue)
})

const currentLabel = computed<string>(() => {
  return selectedOption.value ? selectedOption.value.label : ''
})

const selectOption = (option: Option) => {
  if (!props.isEmbedded) {
    optionsOpened.value = !optionsOpened.value;
  }
  emit('update:modelValue', option.value)
  emit('options-opened', optionsOpened.value)
}

const toggleOptions = () => {
  if (props.isDisabled) {
    return
  }
  optionsOpened.value = !optionsOpened.value
  emit('options-opened', optionsOpened.value)
};

const closeOptions = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  if (!target.closest('.ui-select')) {
    optionsOpened.value = false
    emit('options-opened', optionsOpened.value);
  }
}

onMounted(() => {
  document.addEventListener('click', closeOptions)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeOptions)
})

</script>

<template lang="pug">
.ui-select
  ui-text-field(
    :cannot-type="!isEmbedded"
    :error="error"
    :title="title"
    :placeholder="placeholder"
    :modelValue="currentLabel"
    @click="toggleOptions"
    :isEmbedded="isEmbedded"
    :isAdaptiveHeight="isAdaptiveHeight"
  )
    template(v-if="$slots.error" #error)
      slot(name="error")
  template(v-if="isMobile") 
    //TODO: add default select in mobile
  .ui-select__options(v-else v-show="optionsOpened" :class="{'ui-select__options--embedded': isEmbedded}")
    ui-virtual-scroll(v-if="useVirtualScroll" :items="options" :itemSize="51" key-field="value" v-slot="{ item: option }")
      .ui-select__option(@click.stop="selectOption(option)" :class="{ 'ui-select__option--selected': option.value === selectedOption?.value }")
        .ui-select__option-text
          span(v-if="option.optionLabel" v-html="option.optionLabel")
          template(v-else) {{ option.label }}
    .ui-select__option(v-else v-for="option of options" :key="option.value" @click.stop="selectOption(option)" :class="{ 'ui-select__option--selected': option.value === selectedOption?.value }")
      .ui-select__option-text
        span(v-if="option.optionLabel" v-html="option.optionLabel")
        template(v-else) {{ option.label }}

</template>

<style lang="scss" scoped>
.ui-select {
  cursor: pointer;
  user-select: none;

  &__options {
    width: 100%;
    max-width: get-vw(412);
    margin-left: get-vw(40);
    max-height: get-vw(619);

    &--embedded {
      background: $white;
      left: 0;
      right: 0;
      margin-left: 0;
      margin-top: get-vw(38);
      padding-bottom: get-vw(22);
      max-width: 100%;
      position: absolute;
      z-index: 10;
    }

    .ui-select__option {
      padding-left: get-vw(64);
    }
  }

  &__option-text {
    @include text-t4-medium;
    color: $black;
  }

  &__option {
    padding: get-vw(6);
    margin: {
      top: get-vw(12);
      bottom: get-vw(12);
    }
    border-radius: get-vw(12);

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover, &--selected {
      background-color: $new-fill;
    }
  }
}

@include mobile {
  .ui-select__option {
    margin-bottom: get-vw(12, "sm");
    border-radius: get-vw(8, "sm");
    padding: get-vw(2.27, "sm");
  }

  .ui-select__option-text {
    @include text-t10("sm");
    padding-left: get-vw(25, "sm");
  }

  .ui-select__options--embedded {
    margin-top: get-vw(16, "sm");
  }
}
</style>