<template lang="pug">
.virtual-scroll
  template(v-if="dynamicRowSize")
    DynamicScroller(:items="items" :min-item-size="itemHeight" :key-field="keyField" v-slot="{ item }")
      slot(:item="item")
  template(v-else)
    RecycleScroller(:items="items" :itemSize="itemHeight" :key-field="keyField" v-slot="{ item }")
      slot(:item="item")
</template>

<script>
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { RecycleScroller, DynamicScroller } from 'vue-virtual-scroller'
export default {
  name: 'VirtualScroll',
  components: {
    RecycleScroller,
    DynamicScroller
  },
  props: {
    dynamicRowSize: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    itemSize: {
      type: Number,
      default: 100
    },
    keyField: {
      type: String,
      default: 'id'
    }
  },
  computed: {
    isMobile() {
      return false //TODO: add mobile detection
    },
    itemHeight() {
      if (!window) {
        return this.itemSize
      }

      return this.itemSize / 2303 * window.innerWidth 
    }
  }
}
</script>
